
































































































































































































































































































































































import Vue from "vue";
import { mapGetters } from "vuex";
import { GET_USER_DETAILS } from "@/store/modules/auth";
export default Vue.extend({
  name: "Profile",
  data: (): Record<string, any> => ({
    upskillingIndex1: 0,
    upskillingIndex2: 1,
    yearlyUpskillingItems: [
      {
        title: "AI for Healthcare",
        description:
          "Undergraduate degrees from Khalifa University of Science and Technology are designed",
        logo: require("@/assets/logos/fifth_element.svg")
      },
      {
        title: "Professional Business",
        description:
          "Undergraduate degrees from Khalifa University of Science and Technology are designed",
        logo: require("@/assets/logos/udemy.svg")
      },
      {
        title: "Become a Data Product Manager",
        description:
          "Undergraduate degrees from Khalifa University of Science and Technology are designed",
        logo: require("@/assets/logos/eyouth.svg")
      }
    ]
  }),
  computed: {
    ...mapGetters("auth", {
      get_user_details: GET_USER_DETAILS
    })
  },
  methods: {
    nextUpskillingItemClick() {
      if (this.upskillingIndex2 === this.yearlyUpskillingItems.length - 1) {
        this.upskillingIndex1 = this.upskillingIndex1 + 1;
        this.upskillingIndex2 = 0;
      } else if (
        this.upskillingIndex1 ===
        this.yearlyUpskillingItems.length - 1
      ) {
        this.upskillingIndex1 = 0;
        this.upskillingIndex2 = this.upskillingIndex2 + 1;
      } else {
        this.upskillingIndex1 = this.upskillingIndex1 + 1;
        this.upskillingIndex2 = this.upskillingIndex2 + 1;
      }
    },
    prevUpskillingItemClick() {
      if (this.upskillingIndex2 === 0) {
        this.upskillingIndex1 = this.upskillingIndex1 - 1;
        this.upskillingIndex2 = this.yearlyUpskillingItems.length - 1;
      } else if (this.upskillingIndex1 === 0) {
        this.upskillingIndex1 = this.yearlyUpskillingItems.length - 1;
        this.upskillingIndex2 = this.upskillingIndex2 - 1;
      } else {
        this.upskillingIndex1 = this.upskillingIndex1 - 1;
        this.upskillingIndex2 = this.upskillingIndex2 - 1;
      }
    }
  }
});
